import { Stack } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";

import { Button } from "../../components/Button";
import FriendsBlankState from "../components/BlankState";
import FriendRow from "../components/FriendRow";
import { useAcceptFriendRequest } from "../hooks/useAcceptFriendRequest";
import { type WorkerPublicProfile } from "../hooks/useGetWorkerPublicProfile";
import { useRejectFriendRequest } from "../hooks/useRejectFriendRequest";
import WorkWithFriendsContext from "../WorkWithFriendsContext";

interface Props {
  friends: WorkerPublicProfile[];
}
export default function TabInvites(props: Props) {
  const { friends } = props;

  const { showSuccessToast } = useToast();
  const queryClient = useQueryClient();
  const { setSelectedTab } = useContext(WorkWithFriendsContext);

  const { mutate: rejectFriendRequest } = useRejectFriendRequest({
    onSuccess: () => {
      showSuccessToast("Friend request rejected");
      void queryClient.invalidateQueries({ queryKey: ["inbound-friends-requests"] });
    },
  });

  const { mutate: acceptFriendRequest } = useAcceptFriendRequest({
    onSuccess: () => {
      showSuccessToast("Friend request accepted");
      void queryClient.invalidateQueries({ queryKey: ["friends-list"] });
      void queryClient.invalidateQueries({ queryKey: ["inbound-friends-requests"] });
      if (friends.length <= 1) {
        setSelectedTab("friends");
      }
    },
  });

  if (!friends?.length) {
    return <FriendsBlankState />;
  }

  return (
    <Stack spacing={6}>
      {friends.map((friend) => (
        <FriendRow
          key={friend.id}
          friend={friend}
          actions={
            <>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  acceptFriendRequest(friend.id);
                }}
              >
                Confirm
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  rejectFriendRequest(friend.id);
                }}
              >
                Ignore
              </Button>
            </>
          }
        />
      ))}
    </Stack>
  );
}
