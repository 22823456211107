import { useGetRecentColleagues } from "@src/appV2/Agents/api/useGetRecentColleagues";
import { logError } from "@src/appV2/lib/analytics";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useMemo } from "react";

import { convertShiftToSlotId, useGetShiftSlotsV2 } from "../../Shift/Open/useGetShiftsSlotV2";
import { useGetFriendsList } from "./useGetFriendsList";
import { type WorkerPublicProfile } from "./useGetWorkerPublicProfile";

interface Props {
  shifts: Shift[];
  selfWorkerId: string;
}

export interface WorkersForOverlappingShiftSlots {
  friends: WorkerPublicProfile[];
  recentColleagues: WorkerPublicProfile[];
  otherWorkers: WorkerPublicProfile[];
}

interface Result {
  overlappingShiftWorkersByShiftId: Record<string, WorkersForOverlappingShiftSlots>;
}

function logErrorIfFacilityIdIsMissingFromShifts(shifts: Shift[]) {
  shifts.forEach((shift) => {
    if (!shift.facility.userId) {
      logError("Facility object needed for facilityId is missing for shift", {
        metadata: { shiftId: shift._id },
        error: new Error("Facility object needed for facilityId is missing for shift"),
      });
    }
  });
}

export const useGetWorkersForOverlappingShiftSlots = ({ shifts, selfWorkerId }: Props): Result => {
  const shiftOverlappingSlots = useGetShiftSlotsV2({
    shiftIds: shifts.map((shift) => shift._id),
  });

  logErrorIfFacilityIdIsMissingFromShifts(shifts);

  const { hideAsColleagueOnShifts } = useDefinedWorker();
  const { data: recentColleagues } = useGetRecentColleagues({ enabled: !hideAsColleagueOnShifts });
  const { data: friendsList } = useGetFriendsList();

  return useMemo(() => {
    const recentColleaguesMap = new Map(
      recentColleagues?.map((colleague) => [colleague.colleagueUserId, colleague])
    );
    const friendsMap = new Map(friendsList?.data.map((friend) => [friend.id, friend]));
    const overlappingShiftWorkersByShiftId: Record<string, WorkersForOverlappingShiftSlots> = {};

    shifts.forEach((shift) => {
      const slotId = convertShiftToSlotId({
        start: shift.start,
        end: shift.end,
        facilityId: shift.facility.userId ?? "",
      });

      const overlappingSlot = shiftOverlappingSlots[slotId];
      const workers = overlappingSlot?.shiftAssignments.map(({ worker }) => worker) ?? [];

      const categorizedWorkers = workers.reduce<WorkersForOverlappingShiftSlots>(
        (accumulator, worker) => {
          if (friendsMap.has(worker.id)) {
            accumulator.friends.push(worker);
          } else if (recentColleaguesMap.has(worker.id)) {
            accumulator.recentColleagues.push(worker);
          } else if (worker.id !== selfWorkerId) {
            accumulator.otherWorkers.push(worker);
          }

          return accumulator;
        },
        {
          friends: [],
          recentColleagues: [],
          otherWorkers: [],
        }
      );
      overlappingShiftWorkersByShiftId[shift._id] = categorizedWorkers;
    });

    return { overlappingShiftWorkersByShiftId };
  }, [recentColleagues, selfWorkerId, friendsList?.data, shifts, shiftOverlappingSlots]);
};
