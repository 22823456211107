import { BottomSheet } from "@clipboard-health/ui-components";
import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useState } from "react";

import { IconButton } from "../../../components/IconButton";
import { useRemoveFriend } from "../../hooks/useRemoveFriend";
import WorkWithFriendsContext from "../../WorkWithFriendsContext";
import Confirmation from "./Confirmation";
import Actions from "./RemoveFriendActions";

interface Props {
  onClose: () => void;
  modalState: UseModalState;
}
export default function RemoveFriendBottomSheet(props: Props) {
  const { onClose, modalState } = props;

  const [isRemoving, setIsRemoving] = useState(false);
  const { showSuccessToast } = useToast();
  const queryClient = useQueryClient();
  const { activeFriend } = useContext(WorkWithFriendsContext);

  const { mutate: removeFriend, isLoading: isLoadingRemoval } = useRemoveFriend({
    onSuccess: () => {
      if (isDefined(activeFriend)) {
        showSuccessToast(
          `${activeFriend.attributes.firstName} ${activeFriend.attributes.lastName} has been removed from your friends list`
        );
        void queryClient.invalidateQueries({ queryKey: ["friends-list"] });
      }

      onClose();
    },
  });

  return (
    <BottomSheet modalState={modalState}>
      <Stack flex={1} flexDirection="column" p={6}>
        <Stack flex={1} alignItems="flex-end" p={4}>
          <IconButton variant="outlined" iconType="close" size="small" onClick={onClose} />
        </Stack>
        {isRemoving ? (
          <Confirmation
            pending={isLoadingRemoval}
            onConfirm={() => {
              if (isDefined(activeFriend)) {
                removeFriend(activeFriend.id);
              } else {
                onClose();
              }
            }}
            onCancel={() => {
              onClose();
            }}
          />
        ) : (
          <Stack my={4}>
            <Actions
              onRemove={() => {
                setIsRemoving(true);
              }}
            />
          </Stack>
        )}
      </Stack>
    </BottomSheet>
  );
}
